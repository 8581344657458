<template>
  <div class="container">
    <GeneralTable :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @add-change="handleAdd('add')"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      tableName="ClientManagement"
      >
      <template slot="regionProvince"
        slot-scope="{item}">{{item.regionProvince + item.regionCity + item.regionArea}}</template>
      <template slot="customerType"
        slot-scope="{item}">
        <span v-if="item.customerType === 1">普通客户</span>
        <span v-if="item.customerType === 2">重点客户</span>
        <span v-if="item.customerType === 3">内部客户</span>
      </template>
      <template slot="customerState"
        slot-scope="{item}">
        <span v-if="item.customerState === 1"
          style="color:#07c160">正常</span>
        <span v-if="item.customerState === 2"
          style="color:#409eff">待续期</span>
        <span v-if="item.customerState === 3"
          style="color:#c94242">已过期</span>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <div class="operate">
          <el-button type="text"
            @click="handleAdd('edit',item)"
            v-if="handleHasPerms('M22-client-edit')">编辑</el-button>
          <el-button type="text"
            @click="handleDel([item])"
            v-if="handleHasPerms('M22-client-edit')">删除</el-button>
          <el-button type="text"
            @click="openResetDialog(item)"
            v-if="handleHasPerms('M22-client-edit')">重置密码</el-button>
          <el-button type="text"
            @click="openTreeDialog(item)"
            v-if="handleHasPerms('M22-client-edit')">菜单管理</el-button>
        </div>
      </template>
    </GeneralTable>
    <formDialog v-model="addDialogVisible"
      v-if="addDialogVisible"
      :editType="editType"
      :curInfo="curInfo"
      @clearData="clearData"
      @refreshList="getList"></formDialog>
    <el-dialog title="重置密码"
      :visible.sync="dialogVisible"
      width="30%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      @close="clearData"
      custom-class="resetDialog">
      <div class="reset-password">
        <div class="label">新密码</div>
        <el-input placeholder="最小长度为8位，密码由数字、字母和特殊字符组合"
          v-model="newPassword"
          show-password
          class="input"></el-input>
        <el-button type="primary"
          @click="newPassword = 'Yili666+'">初始密码</el-button>
      </div>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="handleReset">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="菜单管理"
      :visible.sync="treeDialogVisible"
      width="40%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="handleClose"
      @close="treeReset"
      custom-class="treeDialog">
      <div class="content">
        <span class="title">功能权限</span>
        <el-tree :data="menuTree"
          show-checkbox
          node-key="id"
          ref="tree"
          default-expand-all
          :expand-on-click-node="false">
          <span class="custom-tree-node"
            slot-scope="{ node }">
            <span>{{ node.data.name }}</span>
          </span>
        </el-tree>
      </div>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="treeDialogVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="saveClientMenu">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import formDialog from './components/formDialog.vue';
import { clientList, clientDel, exportClientData, clientResetPassword, getMenu, clientTreeMenu, saveClientTree, getListUser } from "@/api/SystemData";
import { hasPerms, validatePassword, generatePassword } from "@/utils/auth";
import { judgePassword  } from "@/utils/index";
export default {
  data() {
    return {
      newPassword: '',
      curInfo: {},
      dialogVisible: false,
      treeDialogVisible: false,
      curTenantId: '',
      menuTree: {},
      option: {
        isAdd: hasPerms('M22-client-add'), //添加 事件 @add-change
        isDel: hasPerms('M22-client-edit'), //删除 @del-change
        isEdit: hasPerms('M22-client-edit'), // 编辑 @edit-change
        isExport: hasPerms("M22-client-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [{
          label: '租户名称',
          prop: 'tenantName',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入租户名称"
        }, {
          label: '所属地区',
          prop: 'region',
          formType: 'region',
        }, {
          label: '客户类型',
          prop: 'customerType',
          formType: 'select',
          filterable: true,
          selectList: [
            { value: '', text: '全部' },
            { value: '1', text: '普通客户' },
            { value: '2', text: '重点客户' },
            { value: '3', text: '内部客户' },
          ]
        },
        {
          label: '联系人',
          prop: 'linkman',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入联系人"
        }, {
          label: '业务人员',
          prop: 'business',
          formType: 'select',
          filterable: true,
          placeholder: "请选择业务人员",
          clearable: true,
          selectList: this.userList
        }, {
          label: '状态',
          prop: 'customerState',
          formType: 'select',
          filterable: true,
          placeholder: "请选择状态",
          selectList: [
            { value: '', text: '全部' },
            { value: 1, text: '正常' },
            { value: 2, text: '待续费' },
            { value: 3, text: '已过期' },
          ]
        }, {
          label: '有效期',
          formType: 'daterange',
          clearable: true,
          format: 'yyyy.MM.dd',
          prop: ['validDateBegin', 'validDateEnd']
        }, {
          label: '创建时间',
          formType: 'daterange',
          clearable: true,
          format: 'yyyy.MM.dd',
          prop: ['createDateBegin', 'createDateEnd']
        }]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "租户名称",
          prop: "tenantName",
          isShow: true,
        },
        {
          label: "租户代码",
          prop: "customerCode",
          isShow: true,
        },
        {
          label: "管理员账号",
          prop: "customerAccount",
          isShow: true,
        },
        {
          label: "所属地区",
          prop: "regionProvince",
          isShow: true,
          isSlot: true,
        },
        {
          label: "联系人",
          prop: "linkman",
          isShow: true,
        },
        {
          label: "联系方式",
          prop: "contactNumber",
          isShow: true,
        },
        {
          label: "客户类型",
          prop: "customerType",
          isShow: true,
          isSlot: true
        },
        {
          label: "业务人员",
          prop: "businessName",
          isShow: true,
        },
        {
          label: "状态",
          prop: "customerState",
          isShow: true,
          isSlot: true
        },
        {
          label: "有效期",
          prop: "validDate",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 300,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      dataList: [],
      addDialogVisible: false,
      form: {},
      editType: '',
      userList: [],
      generatePassword
    };
  },
  components: { GeneralTable, formDialog },
  created() {
    this.getMenuList();
    getListUser().then(res => {
      res.data.forEach(element => {
        element.value = element.id;
        element.text = element.account;
      });
      this.userList = res.data;
      this.option.searchList.find((item) => { return item.label === '业务人员'; }).selectList = this.userList;
    });
  },
  computed: {
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    treeReset() {
      this.$refs.tree.setCheckedKeys([]);
    },
    handleAdd(type, data = {}) {
      this.addDialogVisible = true;
      this.editType = type;
      if (type === 'edit') this.curInfo = data;
    },
    getList(data) {
      clientList(data).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleSearch(data) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.condition = data.keyword;
      this.getList(data);
    },
    handleDel(data) {
      console.log(data);
      if (data && data.length) {
        this.$confirm('确认删除租户?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let temp = {
            ids: [],
            tenantIds: [],
            yiLiUserToken: this.$store.state.appToken
          };

          data.forEach(element => {
            temp.ids.push(element.id);
            temp.tenantIds.push(element.tenantId);
          });
          temp.ids = temp.ids.join(',');
          temp.tenantIds = temp.tenantIds.join(',');
          clientDel(temp).then(res => {
            if (res) {
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.$refs.GeneralTable.getList();
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的租户！');
      }
    },
    handleExport(data) {
      // if (!data || !data.length) {
      //   this.$message.warning('请选择需要导出的租户！');
      //   return false;
      // }
      let ids = [];
      data.forEach(element => {
        ids.push(element.id);
      });
      exportClientData({ tenantIds: ids.join(',') }).then(res => {
        if (res) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
      });
    },
    openResetDialog(data) {
      this.curInfo = data;
      this.dialogVisible = true;
    },
    openTreeDialog(item) {
      this.treeDialogVisible = true;
      this.curTenantId = item.id;
      clientTreeMenu({ tenantId: item.tenantId }).then(res => {
        console.log(res);
        this.$refs.tree.setCheckedKeys(res.data.menu);
      });
    },
    clearData() {
      this.curInfo = {};
    },
    handleReset() {
      if (!validatePassword(this.newPassword)) {
        this.$message({
          type: 'warning',
          message: "请填写正确最小长度为8位，密码由数字、字母和特殊字符组合"
        });
        return false;
      }
      let data = {
        tenantId: this.curInfo.tenantId,
        password: this.newPassword,
        yiLiUserToken: this.$store.state.appToken
      };
      clientResetPassword(data).then(res => {
        if (res) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.dialogVisible = false;
          this.curInfo = {};
          this.newPassword = '';
        }
      });
    },
    getMenuList() {
      getMenu().then(res => {
        console.log(res);
        this.menuTree = res.data.children;
      });
    },
    saveClientMenu() {
      let data = {
        ids: this.curTenantId,
        dataScopeIds: [],
        menuIds: this.$refs.tree.getCheckedKeys(),
      };
      saveClientTree(data).then(res => {
        console.log(res);
        if (res) {
          this.treeDialogVisible = false;
        }
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    }
  },
}
</script>
<style lang="scss" scoped>
.container {
  .operate {
    span {
      margin-right: 10px;
      cursor: pointer;
      color: #409eff;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .resetDialog {
    .reset-password {
      display: flex;
      align-items: center;
      .label {
        width: 100px;
      }
      .input {
        margin-right: 20px;
      }
    }
  }
  .treeDialog {
    .content {
      display: flex;
      height: 500px;
      overflow: auto;
      .title {
        margin-top: 10px;
        font-size: 16px;
      }
    }
  }
}
</style>
