<template>
  <el-dialog :visible="addDialogVisible"
    v-if="addDialogVisible"
    width="800px"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    append-to-body
    @close="handleClose">
    <div slot="title"
      class="header-title">
      <span>{{editType === 'add' ? '添加' : '编辑'}}</span>
    </div>
    <div class="form">
      <div class="item">
        <div class="label required">管理员账号</div>
        <div class="info">
          <el-input placeholder="请填写账号"
            v-model="form.customerAccount"
            maxlength="24"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label required">登录密码</div>
        <div class="info">
          <el-input placeholder="请填写密码"
            show-password
            :disabled="editType === 'edit'"
            v-model="form.customerPassword"></el-input>
        </div>
        <el-button class="btn"
          type="primary" :disabled="editType === 'edit'"
          @click="form.customerPassword = 'Yili666+'">初始密码</el-button>
      </div>
      <div class="line"></div>
      <div class="item">
        <div class="label required">租户名称</div>
        <div class="info">
          <el-input placeholder="请填写租户姓名"
            v-model="form.tenantName"
            maxlength="64"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label required">租户代码</div>
        <div class="info">
          <el-input :disabled="editType === 'edit'"
            placeholder="请填写4位租户代码（保存后不能修改）"
            v-model="form.customerCode"
            maxlength="4"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label">客户类型</div>
        <div class="info">
          <el-radio-group v-model="form.customerType">
            <el-radio :label="1">普通客户</el-radio>
            <el-radio :label="2">重点客户</el-radio>
            <el-radio :label="3">内部客户</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <div class="label required">所在区域</div>
        <div class="info">
          <v-distpicker class="dist-picker"
            :province="form.regionProvince"
            :city="form.regionCity"
            :area="form.regionArea"
            @selected="onSelected"></v-distpicker>
        </div>
      </div>
      <div class="item">
        <div class="label required">详细地址</div>
        <div class="info">
          <el-input placeholder="请填写详细地址"
            v-model="form.regionAddress"
            maxlength="64"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label">联系人</div>
        <div class="info">
          <el-input placeholder="请填写联系人"
            v-model="form.linkman"
            maxlength="24"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label required">联系方式</div>
        <div class="info">
          <el-input placeholder="请填写联系方式"
            v-model="form.contactNumber"
            maxlength="11"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="label required">业务人员</div>
        <div class="info">
          <el-select v-model="form.business"
            placeholder="请选择">
            <el-option v-for="item in options"
              :key="item.id"
              :label="item.account"
              :value="item.id"></el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <div class="label required">有效期</div>
        <div class="info">
          <el-date-picker v-model="form.validDate"
            align="right"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"></el-date-picker>
          <el-checkbox class="limit"
            v-model="form.checked">不限制</el-checkbox>
        </div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="handleReset"
        v-if="editType === 'edit'">重 置</el-button>
      <el-button type="primary"
        @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VDistpicker from 'v-distpicker';
import { clientAdd, getListUser } from '@/api/SystemData.js';
import { phoneFun, judgePassword } from "@/utils/index";
import { validatePassword, generatePassword } from "@/utils/auth";
export default {
  data() {
    return {
      form: {
        tenantName: '',
        customerCode: '',
        customerType: null,
        regionProvince: '',
        regionCity: '',
        regionArea: '',
        regionAddress: '',
        validDate: '',
        business: '',
        customerAccount: '',
        customerPassword: '',
        linkman: '',
        contactNumber: '',
      },
      options: [],
      generatePassword,
    };
  },
  components: { VDistpicker },
  model: {
    prop: "addDialogVisible",
    event: "change"
  },
  props: {
    addDialogVisible: {
      type: Boolean,
      default: false
    },
    editType: {
      type: String,
      default: 'add'
    },
    curInfo: {
      type: Object,
      default: {}
    }
  },
  created() {
    if (this.editType === 'edit') {
      this.form = JSON.parse(JSON.stringify(this.curInfo));
      this.form.customerPassword = '123456';
    }
    getListUser().then(res => {
      this.options = res.data;
    });
  },
  computed: {
  },
  methods: {
    handleClose() {
      this.form = {
        tenantName: '',
        customerCode: '',
        customerType: null,
        regionProvince: '',
        regionCity: '',
        regionArea: '',
        regionAddress: '',
        validDate: '',
        business: '',
        customerAccount: '',
        customerPassword: '',
        linkman: '',
        contactNumber: '',
      };
      this.$emit("change", false);
      this.$emit("clearData");
    },
    onSelected(e) {
      this.form.regionProvince = e.province.value || '';
      this.form.regionCity = e.city.value || '';
      this.form.regionArea = e.area.value || '';
    },
    submit() {
      if (this.form.checked) {
        this.form.validDate = '2050-01-01 00:00:00';
      }
      if (!this.validateData()) return;
      this.form.yiLiUserToken = this.$store.state.appToken;
      this.form.nowUserId = this.$store.state.userInfo.userId;
      let temp = JSON.parse(JSON.stringify(this.form));
      if (this.editType === 'edit') {
        delete temp.customerPassword;
        delete temp.adminUserId;
      }
      clientAdd(temp).then(res => {
        if (res && res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.handleClose();
          this.$emit("refreshList");
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(err => {
        console.log(err);
      });
    },
    validateData() {
      let {
        customerAccount,
        customerPassword,
        tenantName,
        customerCode,
        regionProvince,
        regionAddress,
        business,
        contactNumber,
        validDate } = this.form;
      if (this.editType === 'add') {
        if (!customerPassword) {
          this.$message({
            type: 'warning',
            message: "请填写登录密码"
          });
          return false;
        } else if (!validatePassword(customerPassword)) {
          this.$message({
            type: 'warning',
            message: "请填写正确最小长度为8位，密码由数字、字母和特殊字符组合"
          });
          return false;
        }
      }
      if (!customerAccount || !contactNumber || !tenantName || !customerCode || !regionProvince || !regionAddress || !business || !validDate) {
        this.$message({
          type: 'warning',
          message: "请完善表单信息"
        });
        return false;
      }
      if (customerCode.length !== 4) {
        this.$message({
          type: 'warning',
          message: "租户代码长度需为4"
        });
        return false;
      }
      if (!phoneFun(contactNumber)) {
        this.$message({
          type: 'warning',
          message: "请输入正确的联系方式"
        });
        return false;
      }

      return true;
    },
    handleReset() {
      if (this.editType === 'add') {
        this.form = {
          tenantName: '',
          customerCode: '',
          customerType: null,
          regionProvince: '',
          regionCity: '',
          regionArea: '',
          regionAddress: '',
          validDate: '',
          business: '',
          customerAccount: '',
          customerPassword: '',
          linkman: '',
          contactNumber: '',
        };
      } else {
        this.form = JSON.parse(JSON.stringify(this.curInfo));
        this.form.customerPassword = '123456';
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.header-title {
  background: #4b7bca;
  color: #fff;
  padding: 15px 20px;
  line-height: normal;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  .item {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .label {
      margin-right: 15px;
      position: relative;
      min-width: 70px;
    }
    .required::before {
      position: absolute;
      left: -10px;
      top: -5px;
      content: '*';
      color: red;
      font-weight: bold;
      font-size: 18px;
    }
    .info {
      width: 500px;
      .limit {
        margin-left: 15px;
      }
    }
    .btn {
      position: absolute;
      right: 25px;
    }
  }
  .line {
    margin-bottom: 15px;
    width: 100%;
    border-top: 1px solid #e7e7e7;
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
  position: relative;
  .el-dialog__headerbtn {
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__close {
      color: #fff;
      font-size: 20px;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 10px;
  overflow: hidden;
}
::v-deep .distpicker-address-wrapper select {
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
</style>
